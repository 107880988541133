/**
 * 注册表单
 * @author Jianbo
 * 注: 属性命名方式以服务端为参考
 */

/**
 * 手机号校验
 * @param string tel
 * @return string
 */
export const telValidator = function (tel) {
  if (!tel) return '请填写手机号';
  if (tel.length < 11) return '请填写正确的手机号';
  return null;
};

// 昵称校验
export const nameValidator = function (name) {
  if (!name) return '请填写昵称';
  if (name.length > 20) return '昵称过长';
  const reg = /\s/;
  const hasInvalidCharacter = reg.test(name);
  if (hasInvalidCharacter) return '不支持特殊字符';
  return null;
};

// 密码校验
export const passwordValidator = function (password) {
  if (!password) return '请填写密码';
  if (password.length < 6) return '密码长度不小于6位';
  return null;
};

// 确认密码校验
export const confirmPasswordValidator = function (password) {
  if (!password) return '请填写确认密码';
  if (password.length < 6) return '密码长度不小于6位';
  return null;
};

export class RegisterForm {
  // 手机号
  tel = ''

  // 注册验证码
  tel_code = ''

  // 昵称
  nickname = ''

  // 密码
  passwd = ''

  // 确认密码
  confirm_passwd = ''

  // 服务协议
  agreement = false

  // 格式化处理数据
  format() {
    // 去除密码前后的空格
    this.tel = this.tel.trim();
    this.passwd = this.passwd.trim();
    this.confirm_passwd = this.confirm_passwd.trim();
  }

  // 不合法
  invalid() {
    // 先格式化
    this.format();

    // 手机号
    const telInvalid = this.invalidTel();
    if (telInvalid) return telInvalid;
    // 密码校验
    const passwordError = this.invalidPassword();
    if (passwordError) return passwordError;

    // 短信验证码
    const invalidTelCode = this.invalidTelCode();
    if (invalidTelCode) return invalidTelCode;
    // 协议
    const invalideAgreement = this.invalideAgreement();
    if (invalideAgreement) return invalideAgreement;
    return null;
  }

  // 手机号校验
  invalidTel() {
    return telValidator(this.tel);
  }

  // 名字校验
  invalidName() {
    return nameValidator(this.nickname);
  }

  // 短信验证码
  invalidTelCode() {
    if (!this.tel_code) return '请输入验证码';
    if (this.tel_code.length < 4) return '验证不少于4位';
    return null;
  }

  // 服务协议
  invalideAgreement() {
    if (!this.agreement) return '请阅读服务协议';
    return null;
  }

  // 密码校验
  invalidPassword() {
    if (!this.passwd) return '请填写密码';
    if (this.passwd.length < 6) return '密码长度不小于6位';
    if (!this.confirm_passwd) return '请输入确认密码';
    if (this.confirm_passwd !== this.passwd) return '两次输入的密码不同';
    return null;
  }
}

export default RegisterForm;
