<template lang="html">
<transition name="fadeIn">
  <div class="login-form-container register-container">
    <form class="" @submit.prevent="onSubmit">
      <div class="login-form-group">
          <r-input v-model="form.tel" placeholder="手机号" :validator="telValidator"/>
      </div>
      <div class="login-form-group">
          <r-input v-model="form.nickname" placeholder="昵称" :validator="nameValidator"/>
      </div>
      <div class="login-form-group">
          <r-input
            type="password"
            v-model="form.passwd"
            placeholder="密码"
            :validator="passwordValidator"/>
      </div>
      <div class="login-form-group">
          <r-input
            type="password"
            v-model="form.confirm_passwd"
            placeholder="确认密码"
            :validator="confirmPasswordValidator"/>
      </div>

      <div class="login-form-group">
        <div class="sms-container">
          <r-input class="sms-input" type="number" v-model="form.tel_code" placeholder="短信验证码"/>
          <button type="button" name="button"
            :disabled="isSendSMSButtonDisabled"
            tabindex="-1" @click="onCodeBtnClick">
            <span class="" v-if="!seconds">发送验证码</span>
            <span class="seconds" v-if="seconds">{{seconds}}s</span>
          </button>
        </div>
      </div>
      <div class="login-form-group">
        <div class="agreement">
          <input type="checkbox" id="agreement" v-model="form.agreement">
          <label for="agreement">已阅读并同意
            <a class="link" href="https://api.wanfangche.com/pages/agreement/">《服务协议》</a>
            <span>及</span>
            <a class="link" href="https://api.wanfangche.com/pages/privacy/">《隐私政策》</a>
          </label>
        </div>
      </div>
      <transition name="fadeIn">
        <div class="login-err-message" v-show="message">{{message}}</div>
      </transition>
      <div class="login-form-group">
        <button type="submit" class="btn-submit" :disabled="submitDisabled">
          <i class="fa fa-spinner fa-spin" v-if="onSubmitting"></i>
          <i class="fa fa-check" v-if="onSuccess"></i>
          注册
        </button>
      </div>
      <div class="login-form-group register-help">
        已有账号? 去<span class="text-success" @click="onLoginClick">登录</span>
      </div>
    </form>
  </div>
</transition>
</template>

<script>
import {
  RegisterForm,
  telValidator,
  nameValidator,
  passwordValidator,
  confirmPasswordValidator,
} from "../../form/account/RegisterForm";
import RInput from "../../components/r-input.vue";
import { RegisterFactory } from "../../factory/RegisterFactory";

export default {
  components: {
    RInput,
  },
  data: () => ({
    telValidator,
    nameValidator,
    passwordValidator,
    confirmPasswordValidator,
    form: new RegisterForm(),
    seconds: 0,
    timer: null,
    message: null,
    onSubmitting: false,
    onSuccess: false,
  }),
  computed: {
    isSendSMSButtonDisabled() {
      return Boolean(!this.form.tel || this.form.tel.length < 10 || this.timer);
    },
    submitDisabled() {
      return Boolean(this.form.invalidTel() || this.form.invalidName());
    },
  },
  methods: {
    // 点击提交按钮
    onSubmit() {
      this.message = this.form.invalid();
      if (!this.message && !this.onSubmitting && !this.onSuccess) {
        this.trySubmit();
      }
    },
    // 提交
    trySubmit() {
      this.onSubmitting = true;
      RegisterFactory.doRegist(this.form)
        .then((res) => {
          this.onSubmitting = false;
          this.onSuccess = true;
          this.$emit("on-success", res);
        })
        .catch((e) => {
          this.onSubmitting = false;
          this.message = e.message;
        });
    },
    // 点击发送短信按钮
    onCodeBtnClick() {
      if (!this.timer) {
        this.startTimer();
        const { tel } = this.form;
        RegisterFactory.sendRegistSmsCode({ tel })
          .then(() => {
            // do nothing
          })
          .catch((e) => {
            this.message = e.message;
          });
      }
    },
    // 倒计时器
    startTimer() {
      const MAX_SECONDS = 60;
      this.seconds = MAX_SECONDS;
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
        }
        if (this.seconds <= 0) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    // 点击登录按钮时
    onLoginClick() {
      this.$emit("on-login-click");
    },
  },
};
</script>

<style lang="css" >
@import "../../css/account.css";
</style>
