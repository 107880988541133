/**
 * 注册
 */
import api from '../api';
import axios from '../axios';

export class RegisterService {
  // 提交注册
  static doRegist(data) {
    return axios.post(api.account.regist, data);
  }

  // 发送注册验证码
  static sendRegistSmsCode({ tel }) {
    return axios.post(api.account.registerSendCode, { tel });
  }
}

export default RegisterService;
