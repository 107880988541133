<template lang="html">
  <div class="r-inpput-container" :class="{'r-input-err': showError}">
    <div class="r-input" :class="{'r-input-active': active, 'r-input-focus': isFocus}">
      <div class="r-placeholder" @click="setFocus">{{placeholder}}</div>
      <div class="">
        <input ref="textInput"
          :type="type"
          @focus="onFocus"
          @blur="onBlur"
          @input="onInput"
          @keydown="onKeyDown"
          v-model="inputValue"
          class="r-input-control">
      </div>
      <button type="button"
        tabindex="-1"
        name="button"
        class="input-remove"
        v-show="hasValue"
        @click="resetValue">
        <i class="r-icon-delete"></i>
      </button>
    </div>
    <div class="r-imput-error-message">
      {{err}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    validator: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    isFocus: false,
    inputValue: '',
    err: '',
  }),
  computed: {
    active() {
      return Boolean(this.isFocus || (this.inputValue && this.inputValue.length > 0));
    },
    hasValue() {
      return Boolean(this.inputValue && this.inputValue.length > 0);
    },
    showError() {
      return Boolean(this.inputValue && this.err);
    },
  },
  methods: {
    // focus alias setFocus
    focus() {
      this.setFocus();
    },
    onFocus(e) {
      this.isFocus = true;
      this.err = '';
      this.$emit('focus', e);
    },
    onBlur(e) {
      this.isFocus = false;
      this.$emit('blur', e);
      if (typeof this.validator === 'function') {
        this.err = this.validator(this.inputValue);
      }
    },
    resetValue() {
      this.setFocus();
      this.inputValue = '';
    },
    setFocus() {
      this.$refs.textInput.focus();
    },
    onInput(e) {
      this.$emit('on-input', e.target.value);
    },
    onKeyDown(e) {
      const key = e.which;
      if (key === 13) {
        e.preventDefault();
        this.$emit('on-enter', e);
      }
    },
  },
  created() {
    this.inputValue = this.value;
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    inputValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="css" scoped>
.r-input {
  border: 1px solid rgba(237, 237, 237, 1);
  border-radius: 4px 4px;
  background-color: none;
  position: relative;
  box-sizing: border-box;
  display: block;
}
.r-input-control {
  font-size: 14px;
  line-height: 22px;
  padding: 10px 15px;
  border: 0;
  background-color: inherit;
  border-radius: 4px 4px;
  display: block;
  width: 100%;
  transition: all .25s ease;
}
.r-placeholder {
  box-sizing: border-box;
  position: absolute;
  font-size: 16px;
  line-height: 34px;
  top: 0;
  left: 15px;
  padding: 4px 10px;
  background-color: inherit;
  border-radius: 4px 4px;
  transition: top,left,font-size,line-height .2s ease;
  z-index: 1;
  cursor: text;
  color: #333;
}
.r-input.r-input-active .r-placeholder {
  font-size: 12px;
  line-height: 1em;
  top: -12px;
  left: 10px;
  font-weight: bold;
  background-color: #FFF;
}
.r-input.r-input-focus {
  border-color: rgba(49, 194, 124, .5);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.r-input.r-input-focus .r-placeholder {
  /* color: rgba(49, 194, 124, 1); */
  background-color: rgba(49, 194, 124, 1);
  color: #FFF;
}
.input-remove {
  border: none;
  background-color: inherit;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-size: 14px;
  line-height: 42px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  border: none;
}
.r-icon-delete {
  width: 12px;
  height: 12px;
  display: inline-block;
  line-height: 12px;
  background-image: url('http://cdn.wanfangche.com/icon/icon-wfc-delate%403x.png');
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: 0 0;
}
/* 错误提示 */
.r-input-err .r-input{
  border-color: #FF4900;
}
.r-input-err .r-placeholder{
  color: #FF4900;
}
.r-imput-error-message {
  font-size: 12px;
  color: #FF4900;
  padding: 2px 2px;
  display: none;
  text-align: left;
}
.r-input-err .r-imput-error-message{
  display: block;
}
</style>
