/**
 * 注册服务
 * @author Jianbo
 * @time  2020-04-15
 */
import { RegisterService } from '../service/RegisterService';
import { TokenStorageFactory } from './TokenStorageFactory';
import { AccountFactory } from './AccountFactory';

export class RegisterFactory {
  // 发送短信验证码
  static sendRegistSmsCode({ tel }) {
    return RegisterService.sendRegistSmsCode({ tel }).then((res) => {
      if (res.data.code === 200) {
        return Promise.resolve('success');
      }
      return Promise.reject(new Error(res.data.msg));
    });
  }

  // 提交注册
  static doRegist(form) {
    return RegisterService.doRegist(form).then((res) => {
      if (res.data.code === 200) {
        const { authorization } = res.headers;
        try {
          // 保存token
          TokenStorageFactory.saveToken({ token: authorization });
          // 保存登录用户信息
          AccountFactory.initLoginUser();
        } catch (e) {
          // do nothing
        }
        return Promise.resolve(res.data.data);
      }
      return Promise.reject(new Error(res.data.msg));
    });
  }
}

export default RegisterFactory;
